<template>
  <!--begin::Quotation Listing-->
  <div class="proforma-template">
    <PageHeaderCount
      moduleType="proforma"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_proforma"
      countkey="proforma_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'proforma-listing'"
      v-if="getPermission('proforma:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('proforma:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <template v-if="item.value == 'submit_for_approval'">
                          <template v-if="userDetails.role == 4">
                            <v-list-item-title
                              v-text="'Submied For Approval'"
                            ></v-list-item-title>
                          </template>
                          <template v-else>
                            <v-list-item-title
                              v-text="'Pending For Approval'"
                            ></v-list-item-title>
                          </template>
                        </template>
                        <template v-else>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </template>
                        <template v-if="item.value == 'submit_for_approval'">
                          <template v-if="userDetails.role == 4">
                            <v-list-item-subtitle
                              v-text="'Submied For Approval'"
                            ></v-list-item-subtitle>
                          </template>
                          <template v-else>
                            <v-list-item-subtitle
                              v-text="'Pending For Approval'"
                            ></v-list-item-subtitle>
                          </template>
                        </template>
                        <!--   <template v-else>
                              <v-list-item-subtitle
                                v-text="item.description"
                              ></v-list-item-subtitle>
                            </template> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_proforma
                          }}</template>
                          <template v-else>{{
                            item.proforma_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex quotation-listing-button-right"
              >
                <template v-if="getPermission('proforma:create') & false">
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('proforma.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <v-btn
                    v-if="false"
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-export</v-icon> Export
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  depressed
                  v-if="!isEngineerChannel() && false"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'proforma',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button pr-0"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu
                  content-class="custom-menu-list"
                  offset-y
                  left
                  v-if="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Proforma"
          :basicSearchFields="['barcode', 'title']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="quotation-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode
                            route="proforma.detail"
                            :barcode="data.barcode"
                            :id="data.id"
                          ></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'order_barcode'">
                          <v-chip
                            label
                            color="red"
                            class="text-white p-3 mb-1"
                            outlined
                            small
                            >{{ data?.order_relaion?.barcode }}
                          </v-chip>
                        </template>
                        <template v-else-if="cols.field == 'files'"> </template>
                        <template v-else-if="cols.field == 'date'">
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.added_at"
                          >
                            <b>Created: </b> {{ formatDate(data.added_at) }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'job_title'">
                          <div
                            class="quotation-listing-customer"
                            style="width: 200px"
                          >
                            <p
                              v-if="data.job_title"
                              class="m-0 custom-nowrap-ellipsis"
                            >
                              {{ data.job_title }}
                            </p>
                            <template v-else
                              ><em class="text-muted">No title</em></template
                            >
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="quotation-listing-status">
                            <v-menu
                              :disabled="data.status == 2 ? true : false"
                              transition="slide-y-transition"
                              bottom
                              content-class="custom-menu-list"
                              offset-y
                              left
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  label
                                  :color="getStatusColor(data.status)"
                                  text-color="white"
                                  class="ml-2 text-uppercase font-weight-600"
                                  small
                                >
                                  {{ getStatus(data.status) }}
                                  <v-icon v-if="data.status != 2" right
                                    >mdi-chevron-down</v-icon
                                  >
                                </v-chip>
                              </template>
                              <v-list>
                                <template v-for="(status, index) in statusList">
                                  <v-list-item
                                    v-if="status.text && status.value != 'all'"
                                    v-on:click="
                                      updateStatus(status.status, data)
                                    "
                                    link
                                    :key="index"
                                    style="padding: 0px"
                                  >
                                    <v-list-item-title
                                      class="font-weight-500 font-size-14"
                                    >
                                      <div class="d-flex">
                                        <div
                                          :style="{
                                            backgroundColor: status.color,
                                            height: '15px',
                                            width: '15px',
                                            borderRadius: '50%',
                                          }"
                                          class="ml-2"
                                        ></div>

                                        <v-chip
                                          label
                                          :color="status.color"
                                          text-color="white"
                                          class="ml-2 text-uppercase font-weight-600"
                                          small
                                        >
                                          {{ status.text }}
                                        </v-chip>
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item>
                                </template>
                              </v-list>
                            </v-menu>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'type'">
                          <div class="my-0 d-flex">
                            <div style="width: 88px !important">
                              <v-chip
                                v-if="data?.proforma_type"
                                label
                                :color="
                                  data?.proforma_type == 'initial'
                                    ? 'red'
                                    : 'green'
                                "
                                class="text-white p-3 mb-1 text-capitalize"
                                outlined
                                small
                              >
                                {{ data?.proforma_type }}
                              </v-chip>
                            </div>

                            <v-icon
                              color="red"
                              v-on:click.stop.prevent="
                                downloadFile(data.pdf_url)
                              "
                              :disabled="!data.pdf_url"
                              >mdi-file-pdf-box</v-icon
                            >
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'invoice'">
                          <div style="position: relative">
                            <template v-if="data.status == 2">
                              <template
                                v-if="data && data.invoice && data?.status == 2"
                              >
                                <div class="d-flex">
                                  <p
                                    class="m-0 custom-nowrap-ellipsis"
                                    v-on:click.stop.prevent="
                                      invoiceViewDialog(data.invoice.id)
                                    "
                                  >
                                    <b>Date: </b>
                                    <template v-if="data.invoice?.invoice_date">
                                      {{
                                        formatDate(data.invoice.invoice_date)
                                      }}
                                    </template>
                                  </p>
                                </div>
                                <div
                                  class="inv-btn-position"
                                  v-if="
                                    data?.invoice?.payment_status != 'complate'
                                  "
                                >
                                  <v-icon
                                    v-on:click.stop.prevent="
                                      invoiceUpdateDialog(data)
                                    "
                                    color="green"
                                    size="25"
                                    >mdi-pencil</v-icon
                                  >
                                </div>
                                <p
                                  class="m-0 custom-nowrap-ellipsis"
                                  v-if="data.invoice?.invoice_amount"
                                  v-on:click.stop.prevent="
                                    invoiceViewDialog(data.invoice.id)
                                  "
                                >
                                  <b>Invoice: </b>
                                  <template v-if="data.invoice?.invoice_amount">
                                    {{
                                      formatMoney(data.invoice.invoice_amount)
                                    }}
                                  </template>
                                </p>
                                <p
                                  class="m-0 custom-nowrap-ellipsis"
                                  v-if="data.invoice?.collected_amount"
                                  v-on:click.stop.prevent="
                                    invoiceViewDialog(data.invoice.id)
                                  "
                                >
                                  <b>Collected: </b>
                                  <template
                                    v-if="data.invoice?.collected_amount"
                                  >
                                    {{
                                      formatMoney(data.invoice.collected_amount)
                                    }}
                                  </template>
                                </p>
                                <p
                                  class="m-0 custom-nowrap-ellipsis"
                                  v-if="data?.invoice.payment_status"
                                  v-on:click.stop.prevent="
                                    invoiceViewDialog(data.invoice.id)
                                  "
                                >
                                  <b>Payment Status: </b>
                                  <template v-if="data?.invoice.payment_status">
                                    <v-chip
                                      label
                                      small
                                      :color="
                                        data.invoice.payment_status ==
                                        'complate'
                                          ? 'green'
                                          : 'red'
                                      "
                                      text-color=""
                                      outlined
                                    >
                                      <template
                                        v-if="
                                          data.invoice.payment_status ==
                                          'complate'
                                        "
                                      >
                                        Complete
                                      </template>
                                      <template v-else> Pending </template>
                                    </v-chip>
                                  </template>
                                </p>
                              </template>
                              <template
                                v-if="data?.status == 2 && !data?.invoice"
                              >
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                    z-index="99"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        style="height: 22px !important"
                                        active-class="line-item-type-active"
                                        color="cyan"
                                        class="custom-bold-button text-white"
                                        v-bind="attrs"
                                        v-on="on"
                                        v-on:click.stop.prevent="
                                          invoiceDialog(data)
                                        "
                                      >
                                        + Invoice
                                      </v-btn>

                                      <!--  <v-btn
                                        depressed
                                        :disabled="dataLoading"
                                        class="white--text"
                                        color="cyan"
                                        v-on:click.stop.prevent="
                                          invoiceDialog(data)
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon dark left>mdi-receipt</v-icon>
                                        Invoice
                                      </v-btn>
 -->
                                      <!--  <v-icon
                                        v-on:click.stop.prevent="
                                          invoiceDialog(data)
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-3"
                                        color="cyan"
                                        >mdi-receipt</v-icon
                                      > -->
                                    </template>
                                    <span>Create Invoice</span>
                                  </v-tooltip>
                                </p>
                              </template>
                            </template>
                            <div v-else class="font-weight-700 font-size-19">
                              --
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_name'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.company_name
                                "
                              >
                                {{ data.customer.company_name }}
                              </template>
                              <em class="text-muted" v-else> no company</em>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'discount'">
                          <div class="quotation-listing-customer">
                            <template v-if="data.proforma_type != 'initial'">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Discount </b>
                                <label class="m-0">
                                  <span
                                    v-if="data.discount_value_type == 1"
                                    class="font-weight-700"
                                    >({{ data.discount_value }}) :
                                    {{
                                      $accountingJS.formatMoney(
                                        data.discount_amount
                                      )
                                    }}</span
                                  >
                                  <span
                                    v-if="data.discount_value_type == 2"
                                    class="font-weight-700"
                                    >({{ data.discount_value }}%) :
                                    {{
                                      $accountingJS.formatMoney(
                                        data.discount_amount
                                      )
                                    }}</span
                                  >
                                </label>
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Lump Sum Discount </b>
                                <label class="m-0">
                                  <span
                                    v-if="data.lump_discount_type == 1"
                                    class="font-weight-700"
                                    >({{
                                      $accountingJS.formatMoney(
                                        data.lump_discount_value
                                      )
                                    }}) :
                                    {{
                                      $accountingJS.formatMoney(
                                        data.lump_sum_discount
                                      )
                                    }}</span
                                  >
                                  <span
                                    v-if="data.lump_discount_type == 2"
                                    class="font-weight-700"
                                    >({{ data.lump_discount_value }}%) :
                                    {{
                                      $accountingJS.formatMoney(
                                        data.lump_sum_discount
                                      )
                                    }}</span
                                  >
                                </label>
                              </p>
                            </template>
                            <template v-else>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <label class="m-0">
                                  <v-chip
                                    label
                                    small
                                    color="red"
                                    text-color="white"
                                    >Auto Deduct</v-chip
                                  >
                                </label>
                              </p>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'tax'">
                          <div class="quotation-listing-customer">
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.tax_amount > 0"
                            >
                              <b>GST {{ data.tax_value }} % : </b>
                              <span class="font-weight-700">{{
                                $accountingJS.formatMoney(data.tax_amount)
                              }}</span>
                            </p>
                            <div v-else class="font-weight-700 font-size-19">
                              --
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_detail'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template v-if="data?.customer?.company_email">
                                {{ data?.customer.company_email }}
                              </template>
                              <em class="text-muted" v-else> no email</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template v-if="data?.customer?.company_number">
                                {{ data?.customer.company_number }}
                              </template>
                              <em class="text-muted" v-else>
                                no phone number</em
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="quotation-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <span class="font-weight-700">
                                {{
                                  $accountingJS.formatMoney(data.sub_total)
                                }}</span
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'total'">
                          <div class="quotation-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <span class="font-weight-700">
                                {{
                                  $accountingJS.formatMoney(data.total)
                                }}</span
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.created_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColShow?.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no proforma at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>proforma Quotation</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-if="false"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                    <DateRangePicker
                      placeholder="Data Range"
                      v-model="dates"
                      v-on:click:clear="clearFilter('dates')"
                      class="mr-2"
                    ></DateRangePicker>
                  </v-col>

                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <Datepicker
                        v-if="field == 'open_till'"
                        solo
                        flat
                        clearable
                        v-model.trim="listingSearch[field]"
                        label="Open Still"
                      ></Datepicker>

                      <v-text-field
                        v-else
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'sales'
                            ? 'Sales Person'
                            : field == 'customer_detail[value]'
                            ? 'Customer Details[Phone Number]'
                            : getFilterLabel(field, 'Quotation')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <Invoice
          v-if="invoice_dialog"
          :reassign-schedule-dialog="invoice_dialog"
          v-on:close="invoice_dialog = false"
          :order-id="typeId"
          :updated-id="invoice_id"
          :proforma-data="proformaParent"
          v-on:success="getRows()"
        ></Invoice>
        <InvoiceView
          :invoice-view-dialog="invoice_view_dialog"
          v-on:close="invoice_view_dialog = false"
          :updated-id="invoice_id"
        ></InvoiceView>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Quotation Listing-->
</template>
    
    <script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import JwtService from "@/core/services/jwt.service";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import DateRangePicker from "@/view/components/DateRangePicker";
import { PATCH } from "@/core/services/store/request.module";
import Invoice from "@/view/pages/partials/Proforma-Invoice";
import InvoiceView from "@/view/pages/partials/Proforma-Invoice-View";
//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "proforma-list",
  data() {
    return {
      download_date: moment().format("YYYY-MM-DD"),
      userDetails: {},
      exportLoading: false,
      dates: [],
      pageModule: "proforma-listing",
      routeAPI: "performa",
      routeName: "proforma",
      routeDetailName: "proforma.detail",
      importDialog: false,
      status: "all",
      statusList: [],
      customThead: [],
      rowData: [],
      invoice_dialog: false,
      invoice_view_dialog: false,
      invoice_id: 0,
      typeId: 0,
      proformaParent: {},
      moreActions: [
        {
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },

        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [],
    };
  },
  components: {
    draggable,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    Datepicker,
    DateRangePicker,
    InvoiceView,
    Invoice,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    downloadFile(url) {
      if (!url) {
        return false;
      }
      window.open(url, "_blank");
    },
    invoiceDialog(parent) {
      this.typeId = parent.order_id;
      this.invoice_id = 0;
      this.proformaParent = parent;
      this.invoice_dialog = true;
    },
    invoiceUpdateDialog(parent) {
      this.typeId = parent.order_id;
      this.proformaParent = parent;
      this.invoice_id = parent?.invoice?.id;
      this.invoice_dialog = true;
    },
    invoiceViewDialog(invoice) {
      this.typeId = parent.order_id;
      this.invoice_id = invoice;
      this.invoice_view_dialog = true;
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Draft";
        case 2:
          return "Send";
        case 3:
          return "Completed";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 1:
          return "cyan";
        case 2:
          return "orange";
        case 3:
          return "green";
      }
    },
    updateStatus(proforma_status, row) {
      if (this.dataLoading) {
        return false;
      }
      this.dataLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "proforma/" + row.id + "/status",
          data: { status: proforma_status },
        })
        .then(() => {
          this.getRows();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Proforma",
      },
    ]);
    this.userDetails = JwtService.currentUser();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
    